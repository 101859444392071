<template>
  <section>
    <b-navbar
      class="navbar"
      wrapper-class="container"
      spaced
    >
      <template slot="brand">
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/' }"
        >
          <img
            src="@/assets/logo-text.svg"
            class="nav-logo"
            alt="Logo"
          >
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item
          v-if="user.activeRole !== 'agency'"
          tag="router-link"
          :to="{ path: '/overview' }"
        >
          Overview
        </b-navbar-item>
        <b-navbar-item
          v-if="user.activeRole !== 'agency'"
          tag="router-link"
          :to="{ path: '/users' }"
        >
          Search User
        </b-navbar-item>
        <b-navbar-item
          v-if="user.activeRole !== 'agency'"
          tag="router-link"
          :to="{ path: '/podcasts' }"
        >
          Podcast Feed
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/live' }"
        >
          Live Feed
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/topup' }"
        >
          Topup
        </b-navbar-item>
        <b-navbar-item
          v-if="user.activeRole !== 'agency'"
          tag="router-link"
          :to="{ path: '/admin-topup' }"
        >
          Admin Topup
        </b-navbar-item>
        <b-navbar-item
          v-if="user.activeRole !== 'agency'"
          tag="router-link"
          :to="{ path: '/cashout' }"
        >
          Cashout User
        </b-navbar-item>
        <b-navbar-item
          v-if="user.activeRole !== 'agency'"
          tag="router-link"
          :to="{ path: '/report' }"
        >
          User Report
        </b-navbar-item>
        <b-navbar-item
          v-if="user.activeRole !== 'agency'"
          tag="router-link"
          :to="{ path: '/settings' }"
        >
          Settings
        </b-navbar-item>
      </template>
      <template slot="end">
        <div class="user-profile">
          <figure
            class="image is-48x48"
            @click="toggleUserMenu"
          >
            <img
              class="is-rounded profile-image"
              :src="getProfileImageUrl(user.photoURL)"
              loading="lazy"
              alt="profile image"
              @error="fallbackProfileImage"
            >
          </figure>
        </div>
        <b-dropdown
          ref="userMenu"
          aria-role="list"
          position="is-bottom-left"
        >
          <div class="dropdown-item">
            {{ user.email }}
          </div>
          <hr class="dropdown-divider">
          <b-dropdown-item has-link>
            <router-link
              to="/settings"
              exact
            >
              Settings
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item @click.native.prevent="logout()">
            Sign out
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-navbar>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  data () {
    return {
      unreadNotificationCount: 0
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      role: 'role'
    })
  },
  methods: {
    async logout () {
      await this.$store.dispatch('signOutAction')
      if (this.$route.path !== '/login') {
        this.$router.push('/login')
      }
    },
    toggleUserMenu () {
      this.$refs.userMenu.toggle()
    },
    getProfileImageUrl (url) {
      if (url) {
        return url
      } else {
        return 'https://space.pillowcast.app/image/default/default.svg'
      }
    },
    fallbackProfileImage (e) {
      e.target.src = 'https://space.pillowcast.app/image/default/default.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-logo {
  height: 300px;
}
.navbar {
  color: #ffffff;
  border-bottom: 1px solid #29394d;
}
.has-link {
  padding: 0 !important;
  > div {
    cursor: pointer; // Enable click events on iOS
    padding: 0.5rem 1rem;
  }
}
.user-profile:hover {
  cursor: pointer;
}
</style>
